.testimonial{
    margin-top: -2.5rem;
    padding-bottom: 2rem;
    background-color: #6db3c2;
    height: fit-content;
}

.testimonial-title{
    font-size: 3.4rem;
    padding-top: 2rem;
}

.carousel-root {
    width: 100%;
    margin: auto !important;
    height: fit-content;
  }
  
  .carousel .slide {
    background-color: #6db3c2 !important;
    color: black;
    height: 100%;
  }
  
  .carousel .slide img {
    width: 10rem;
    border-radius: 50%;
  }
  
  .myCarousel {
    background: #F0F0F0;
    margin-top: -6%;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 6%;
    padding-bottom: 8%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: fit-content;
  }
  
  .carousel .control-dots {
    padding-left: 5px !important;
    outline: 0;
    bottom: 5% !important;
  }
  
  .myCarousel h3 {
    color: #222;
    font-weight: 100;
    letter-spacing: 0.2px;
    margin-bottom: 4px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: sans-serif;
  }
  
  .myCarousel h4 {
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 0;
    font-weight: 500;
    color: #787878;
    font-family: sans-serif;
  }
  
  .myCarousel p {
    font-weight: 100 !important;
    line-height: 29px !important;
    color: #222;
    
    font-family: sans-serif;
    max-height: 67px;
  }
  
  .myCarousel p:before {
    content: "“";
    color: #aaa;
    /* font-size: 26px; */
    font-family: monospace;
    font-weight: 100;
  }
  
  .myCarousel p:after {
    content: "”";
    color: #aaa;
    /* font-size: 26px; */
    font-family: monospace;
    font-weight: 100;
    line-height: 0;
  }
  
  .carousel .control-dots .dot {
    box-shadow: none !important;
    background: #454545 !important;
    outline: 0;
  }
  
  .carousel.carousel-slider .control-arrow {
    background: #000 !important;
    height: 50px !important;
    position: absolute;
    top: 35% !important;
  }
  
  .App {
    text-align: center;
  }
  
  @media only screen and (max-width: 540px) {
    .testimonial{
      width: 109%;
      margin-left: -1rem;
    }
    .testimonial-title{
      font-size: 3rem;
    }
    .carousel-root {
      outline: 0;
      width: 100% !important;
      margin: auto !important;
    }
    .myCarousel p{
      font-size: 1.3rem;
    }
    .myCarousel h3 {
      font-size: 1.8rem;
    }
    .myCarousel h4{
      font-size: 1.2rem;
    }
    .carousel.carousel-slider .control-arrow {
      display: none !important;
    }
    .myCarousel {
      background: #fafafa;
      margin-top: -9%;
      width: 88%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 8%;
      padding-bottom: 12.5%;
      padding-left: 5%;
      padding-right: 5%;
      border: 1px solid #ddd;
    }
    .first-testimonial{
      overflow: scroll;
    }
  }

  @media only screen and (min-width: 540px) and (max-width:900px){
    .testimonial{
      width: 109%;
      margin-left: -1rem;
    }
    .testimonial-title{
      font-size: 3rem;
    }
    .carousel-root {
      outline: 0;
      width: 100% !important;
      margin: auto !important;
    }
  
    .carousel.carousel-slider .control-arrow {
      display: none !important;
    }
    .myCarousel {
      background: #fafafa;
      margin-top: -9%;
      width: 80%;
      margin-left: 4rem;
      margin-right: auto;
      padding-top: 8%;
      /* padding-bottom: 12.5%; */
      padding-left: 5%;
      padding-right: 5%;
      border: 1px solid #ddd;
      /* height: 30rem; */
    }
    .first-testimonial{
      overflow: scroll;
    }
  }

  @media only screen and (min-width: 900px) and (max-width:1200px){
    .testimonial{
      width: 109%;
      margin-left: -1rem;
    }
    .testimonial-title{
      font-size: 3rem;
    }
    .carousel-root {
      outline: 0;
      width: 100% !important;
      margin: auto !important;
    }
  
    .carousel.carousel-slider .control-arrow {
      display: none !important;
    }
    .myCarousel {
      background: #fafafa;
      margin-top: -9%;
      width: 80%;
      margin-left: 4rem;
      margin-right: auto;
      padding-top: 8%;
      padding-left: 5%;
      padding-right: 5%;
      border: 1px solid #ddd;
      /* height: 30rem; */
    }
    .myCarousel p {
      /* line-height: 35px !important; */
      font-size: 1rem;
    }
    .myCarousel h4{
      font-size: 1.5rem;
    }
    .myCarousel h3{
      font-size: 2rem;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 2000px){
    .myCarousel p {
      line-height: 35px !important;
      font-size: 1.2rem;
    }
    .myCarousel h4{
      font-size: 1.5rem;
    }
    .myCarousel h3{
      font-size: 1.5rem;
    }
    .first-testimonial{
      overflow: scroll;
    }
  }

  @media only screen and (min-width: 2000px) and (max-width: 3099px){
    .myCarousel p {
      line-height: 35px !important;
      font-size: 2.3rem;
    }
    .myCarousel h4{
      font-size: 2.2rem;
    }
    .myCarousel h3{
      font-size: 2.8rem;
    }
  }

  @media only screen and (min-width: 3100px){
    .myCarousel{
      height: 45vh;
    }
    .myCarousel p {
      line-height: 35px !important;
      font-size: 2.5rem;
    }
    .myCarousel h4{
      font-size: 2.5rem;
    }
    .myCarousel h3{
      font-size: 3rem;
    }
  }