.experience {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8rem;
    background-color: #6db3c2;
    
  }

  .h1{
    text-align: center;
  }
  
  .achievement{
      display: flex;
      flex-direction: column;    
      text-align: center;
      margin-bottom: 2.5rem;
  }

  .title{
    align-items: center;
  }
  .circle {
    margin-top: 3rem;
      width: 12rem;
      height: 12rem;
      background: white;
      border-radius: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 2rem;
  }
  .circle::before {
      border-radius: 100%;
      content: '';
      /* background-image: linear-gradient(to bottom, #6db3c2 0%, #94dcec 100%); */
      background-image: linear-gradient(to bottom, #94dcec 0%, #94dcec 100%);
      top: -8px;
      left: -8px;
      bottom: -8px;
      right: -8px;
      position: absolute;
      z-index:-1;
      box-shadow: var(--boxShadow);
  }

  .achievement span:nth-of-type(1){
    font-weight: bolder;
    font-size: x-large;
  }
  
  .achievement span:nth-of-type(2){
      color: white;
      font-weight: bold;
      font-size: x-large;
  }

  /* @media screen and (max-width: 480px) {
    .experience{
        display: flex;
        flex-direction: column;
        transform: scale(0.7);
        gap: 2rem;
        margin-top: 1rem;
        margin-bottom: 14rem;
    }
    .circle{
      width: 7rem;
      height: 7rem;
      font-size: 3rem;
    }
    .achievement span:nth-of-type(1){
      font-size: larger;
    }
    .achievement span:nth-of-type(2){
      font-size: larger;
    }
} */

@media only screen and (max-width: 540px){
  .experience{
    width: 109%;
    margin-left: -1rem;
    display: block;
    padding-bottom: 2rem;
    /* margin-right: 10rem; */
  }
  .circle{
    margin-left: 6rem;
    border-color: #94dcec;
  }
  .achievement span:nth-of-type(2){
    margin-bottom: -3rem;
  }
}

@media only screen and (min-width: 540px) and (max-width: 600px){
  .circle{
    margin-left: 12rem;
  }
}

@media only screen and (min-width: 600px) and (max-width:900px){
  .experience{
    width: 109%;
    margin-left: -2.5rem;
    /* display: block; */
    padding-bottom: 2.5rem;
    gap: 3rem;
    /* margin-right: 10rem; */
  }
  .circle{
    margin-left: 1rem;
    border-color: #94dcec;
    width: 8rem;
    height: 8rem;
    font-size: 2rem;
  }
  .achievement span:nth-of-type(2){
    margin-bottom: -3rem;
  }
}

@media only screen and (min-width: 900px) and (max-width:1200px){
  .experience{
    width: 109%;
    margin-left: -2.5rem;
    /* display: block; */
    padding-bottom: 2.5rem;
    gap: 6rem;
    /* margin-right: 10rem; */
  }
  .circle{
    margin-left: 1rem;
    border-color: #94dcec;
    width: 9rem;
    height: 9rem;
    font-size: 2rem;
  }
  .achievement span:nth-of-type(2){
    margin-bottom: -3rem;
  }
}
