.story{
    background-color: #F0F0F0;
    padding-top: 0.5rem;
    padding-left: 2rem;
    padding-bottom: 1rem;
    text-align: justify;
}

.story_title{
    font-size: 3rem;
}

.story_content{
    margin-right: 3rem;
    justify-content: center;
    font-size: 1.4rem;
    color: var(--gray);
}

.story-content-visible-bod{
    margin-top: 10rem;
}

.story-content-invisible{
    display: none;
}

.story-content-invisible-changed{
    visibility: visible;
}

.btn{
    margin-top: 1rem;
    background: #FFFFFF;
      box-shadow: 0px 19px 60px rgba(0, 0, 0, 0.08);
      border-radius: 7px;
      border: none;
      padding: 10px;
      font-size: 16px;
}

.btn:hover{
    transform: scale(1.1);
    cursor: pointer;
}

.btn-style-changed{
    display: none;
}

@media only screen and (max-width: 540px){

    .story{
        width: 109%;
        margin-left: -1rem;
        padding-left: 0rem;
    }
    .story_title{
        font-size: 2.5rem;
        text-align: center;
    }
    .story_content{
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
    .btn{
        margin-left: 7rem;
    }
}

@media only screen and (min-width: 540px) and (max-width:900px){
    .btn{
        margin-left: 20rem;
    }
}

@media only screen and (min-width: 900px) and (max-width:1200px){
    .btn{
        margin-left: 25rem;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 2000px){
    .btn{
        margin-left: 40rem;
    }
}

@media only screen and (min-width: 2000px){
    .btn{
        margin-left: 77rem;
        font-size: 1.5rem;
    }
    .story_content{
        font-size: 2.4rem;
    }
}