.imageTestimonial{
    margin-top: 0rem;
    padding-bottom: 2rem;
    background-color:#F0F0F0;
    height: fit-content;
}

.carousel-root {
    width: 100%;
    margin: auto !important;
    height: fit-content;
    padding-top: 2rem;
  }
  
  .imageCarousel .slide {
    background-color: #F0F0F0 !important;
    color: black;
    height: 100%;
  }
  
  .carousel .slide img {
    width: 60%;
    border-radius: 0%;
  }
  
  .myImageCarousel {
    background: #F0F0F0;
    margin-top: -6%;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 6%;
    padding-bottom: 8%;
    padding-left: 5%;
    padding-right: 5%;
    height: fit-content;
  }
  
  .carousel .control-dots {
    padding-left: 5px !important;
    outline: 0;
    bottom: 5% !important;
  }
  
  /* .myCarousel h3 {
    color: #222;
    font-weight: 100;
    letter-spacing: 0.2px;
    margin-bottom: 4px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.8rem;
    font-family: sans-serif;
  }
  
  .myCarousel h4 {
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 0;
    font-weight: 500;
    color: #787878;
    font-size: 1.2rem;
    font-family: sans-serif;
  } */
  
  
  
  /* .myCarousel p:before {
    content: "“";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
  }
  
  .myCarousel p:after {
    content: "”";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
    line-height: 0;
  } */
  
  .carousel .control-dots .dot {
    box-shadow: none !important;
    background: #454545 !important;
    outline: 0;
  }
  
  .carousel.carousel-slider .control-arrow {
    background: #000 !important;
    height: 50px !important;
    position: absolute;
    top: 35% !important;
  }
  
  .App {
    text-align: center;
  }
  
  @media only screen and (max-width: 540px)  {
    .imageTestimonial{
      width: 110%;
      margin-left: -1rem;
    }
    .testimonial-title{
      font-size: 3rem;
    }
    .carousel-root {
      outline: 0;
      width: 100% !important;
      margin: auto !important;
    }
  
    .carousel.carousel-slider .control-arrow {
      display: none !important;
    }
    .myImageCarousel {
      background: #fafafa;
      margin-top: -9%;
      width: 88%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 8%;
      padding-bottom: 12.5%;
      padding-left: 5%;
      padding-right: 5%;
      border: 1px solid #ddd;
      height: 30rem;
    }
  
    .carousel .slide img {
      width: 75%;
    }
  }

  @media only screen and (min-width: 540px) and (max-width:900px) {
    .imageTestimonial{
      width: 110%;
      margin-left: -2rem;
    }
    .testimonial-title{
      font-size: 3rem;
    }
    .carousel-root {
      outline: 0;
      width: 100% !important;
      margin: auto !important;
    }
  
    .carousel.carousel-slider .control-arrow {
      display: none !important;
    }
    .myImageCarousel {
      background: #fafafa;
      margin-top: -9%;
      width: 88%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 8%;
      padding-bottom: 12.5%;
      padding-left: 5%;
      padding-right: 5%;
      border: 1px solid #ddd;
      height: 30rem;
    }
  
    .carousel .slide img {
      width: 75%;
    }
  }

  