.cards{
    display: flex;
    background-color: #06667c;
    gap: 10rem;
    padding-bottom: 3rem;
    padding-top: 3rem;
}

.card-first-element{
    align-items: center;
    display: flex;
    flex-direction: column;
}

.first-element-title{
    font-size: x-large;
    font-weight: bold;
}

@media only screen and (max-width: 540px){
    .cards{
        display: block;
        width: 109%;
        margin-left: -1rem;
    }

    .first-card{
        margin-top: -2rem;
    }
}
@media only screen and (min-width: 540px) and (max-width:900px){
    .cards{
        display: block;
        width: 109%;
        margin-left: -1rem;
        margin-right: -2rem;
    }

    .card-first-element{
        margin-right: 2rem
    }

    .first-card{
        margin-top: -2rem;
    }
}
@media only screen and (min-width: 900px) and (max-width: 1200px){
    .cards{
        display: block;
        width: 109%;
        margin-left: -1rem;
        margin-right: -2rem;
    }
    .card-first-element{
        margin-right: 3rem;
    }

    .first-card{
        margin-top: -1rem;
    }
}
/* @media only screen and (min-width: 1200px) and (max-width: 2000px){
    .cards{
        display: block;
        width: 109%;
        margin-left: -1rem;
        margin-right: -2rem;
    }
    .card-first-element{
        margin-right: 3rem;
    }

    .first-card{
        margin-top: -1rem;
    }
} */

@media only screen and (min-width: 1200px) and (max-width: 1600px){
    .card {
        margin-top: 2rem;
        /* height: 35rem; */
        padding-bottom: 3rem;
    }
    .first-card {
        width: 82%;
        margin-left: 1rem;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 2000px){
    .card {
        margin-top: 2rem;
        height: 25rem;
        padding-bottom: 3rem;
    }
    .first-card {
        width: 80%;
        margin-left: 1rem;
    }
    .second-cards{
        width: 70%;
        margin-right: 5rem;
    }
}

@media only screen and (min-width: 2000px) and (max-width: 2385px){
    .card {
        margin-top: 2rem;
        height: 42rem;
        padding-bottom: 3rem;
    }
    .first-card {
        width: 85%;
        margin-left: 1rem;
    }
    .second-cards{
        width: 70%;
        margin-right: 5rem;
    }
}
@media only screen and (min-width: 2385px) and (max-width: 3200px){
    .card {
        margin-top: 2rem;
        height: 38rem;
        padding-bottom: 3rem;
    }
    .first-card {
        width: 85%;
        margin-left: 1rem;
    }
    .second-cards{
        width: 70%;
        margin-right: 5rem;
    }
}
