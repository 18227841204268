.donation{
    background-color: #6db3c2;
    padding-bottom: 3rem;
    padding-left: 7rem;
    padding-right: 7rem;
}

.main-container{
    padding-top: 0.01rem;
    text-align: justify;
    /* padding-left: 7rem;
    padding-right: 7rem; */
    color: #F0F0F0;
}

.main-container-heading{
    font-size: 3.3rem;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    
}

.main-container-description{
    white-space: pre-wrap;
    font-size: 1.2rem;
    font-family: 'Times New Roman', Times, serif;
    
}

.main-container-donation{
    font-size: 1.1rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
}

.main-container-list{
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.2rem;
}
.donate-btn{
    border-radius: 34px;
  border: none;
  color: #6db3c2;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
  background: white;
  cursor: pointer;
}

.second-container{
    display: flex;
    gap: 7rem;
    text-align: justify;
    color: #F0F0F0;
}
.second-container-left{
    width: 78rem;
}

@media only screen and (max-width: 540px){
    .donation{
        margin-left: -1rem;
        width: 109%;
        padding-left: 1rem;
        padding-bottom: 3rem;
    }
    .main-container{
        padding-right: 2rem;
    }
    .main-container-heading{
        font-size: 2rem;
        text-align: center;
    }
    .second-container{
        display: block;
        margin-right: 2rem;
        bottom: 0px;
    }
    .second-container-left{
        width: 22rem;
    }
}

@media only screen and (min-width: 540px)and(max-width: 900px){
    .donation{
        margin-left: -1rem;
        width: 109%;
        padding-left: 1rem;
        padding-bottom: 3rem;
    }
    .main-container{
        padding-right: 2rem;
    }
    .main-container-heading{
        font-size: 2rem;
        text-align: center;
    }
    .second-container{
        display: block;
        margin-right: 2rem;
        bottom: 0px;
    }
    .second-container-left{
        width: 22rem;
    }
}

@media only screen and (min-width: 900)and(max-width: 1200px){
    .donation{
        margin-left: -1rem;
        width: 109%;
        padding-left: 1rem;
        padding-bottom: 3rem;
    }
    .main-container{
        padding-right: 2rem;
    }
    .main-container-heading{
        font-size: 2rem;
        text-align: center;
    }
    .second-container{
        display: block;
        margin-right: 2rem;
        bottom: 0px;
    }
    .second-container-left{
        width: 22rem;
    }
}