.main{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #F0F0F0;
    /* padding-bottom: 1rem; */
}
.main span:nth-of-type(1){
    color: var(--black);
    font-size: 4rem;
    font-weight: bold;
    text-align: center;
}

.main span:nth-of-type(2){
    margin-left: 3rem;
    margin-right: 3rem;
    font-weight: 450;
    color: var(--gray);
    margin-bottom: -3rem;
}

.main1{
    gap: 4rem;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile-card{
    position: relative;
    width: 220px;
    height: 220px;
    background: #ffff;
    padding: 30px;
    border-radius: 50%;
    box-shadow: 0 0 22px #3336;
    transition: .6s;
    margin: 0 25px;
  }
  .profile-card:hover{
    border-radius: 10px;
    background-color: #F0F0F0;
  }
  .profile-card .profile-card-title{
    width: 100%;
    height: 100%;
    transition: .6s;
    z-index: 99;
    text-align: center;
    margin-top: 5rem;
    font-size: xx-large;
    font-weight: 800;
    color: #0b95d2;
  }

  .fourth-card{
    width: 100%;
    height: 100%;
    transition: .6s;
    z-index: 99;
    text-align: center;
    font-size: xx-large;
    font-weight: 800;
    color: #0b95d2;
    margin-top:4rem
  }

  .profile-card:hover .card-title{
    transform: translateY(-60px);
    opacity: 0;
  }
  .caption{
    margin-top: -12rem;
    text-align: center;
    transform: translateY(-80px);
    opacity: 0;
    transition: .6s;
  }
  .profile-card:hover .caption{
    opacity: 1;
  }
  .caption p{
    font-size: 16px;
    color: var(--gray);
    margin: 2px 0 9px 0;
  }
  

@media only screen and (max-width: 540px){
  .main{
    width: 109%;
    margin-left: -1rem;
  }
  .main span:nth-of-type(1){
    font-size: 3rem;
    padding-top: 1rem;
  }
  .main span:nth-of-type(2){
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    text-align: justify;
  }
}

@media only screen and (min-width: 540px) and (max-width:900px){

  .main{
    width: 109%;
    margin-left: -1rem;
  }
  .main span:nth-of-type(1){
    font-size: 3rem;
    padding-top: 1rem;
  }
  .main span:nth-of-type(2){
    margin-left: 2.5rem;
    font-size: 1.4rem;
    margin-right: 5rem;
    text-align: justify;
    margin-bottom: -6rem;
  }
}

@media only screen and (min-width: 900px) and (max-width:1200px){
  .main{
    width: 109%;
    margin-left: -1rem;
  }
  .main span:nth-of-type(1){
    font-size: 3rem;
    padding-top: 1rem;
  }
  .main span:nth-of-type(2){
    margin-left: 2.5rem;
    margin-right: 6rem;
    text-align: justify;
    margin-bottom: -4rem;
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 2000px) {
  .main span:nth-of-type(2){
    font-size: 1.4rem;
}
}

@media only screen and (min-width: 2000px) {
  .main span:nth-of-type(2){
    font-size: 2.4rem;
}
}