.contact-form{
    display: flex;
    /* height: 60vh; */
  padding-top: 2rem;
  margin-left: -5rem;
  background-color: #6db3c2;
}

.c-span1{
    color: var(--black);
    font-size: 3.5rem;
    font-weight: bold;
}

.c-left{
    margin-left: 17rem;
}


.c-right{
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
}

.c-right>form
{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

.c-right .user{
    width: 20rem;
    height: 2rem;
    padding: 0.3em;
    outline: none;
    border: 2px solid #f0f0f0;
    border-radius: 8px;
    font-size: 16px;   
}

textarea{
    height: 4rem;
}


.c-blur1{
    top: 1rem;
    left: 8rem;
}

@media only screen and (max-width: 540px){
    .contact-form{
        width: 126%;
        display: flex;
        flex-direction: column;
        height: fit-content;
        padding-bottom: 1rem;
    }
    .c-left{
        margin-left: 2.5rem;
    }
    .c-span1{
        font-size: 3rem;
    }
    .c-right{
        margin-left: 4rem;
        margin-top: -108rem;
    }
}

@media only screen and (min-width: 530px) and (max-width:900px){
    .contact-form{
        width: 115%;
        display: flex;
        flex-direction: column;
        height: fit-content;
        padding-bottom: 1rem;
    }
    .c-left{
        margin-left: 2.5rem;
    }
    .c-span1{
        font-size: 4rem;
    }
    .c-right{
        margin-left: 4rem;
        margin-top: -108rem;
    }
}

@media only screen and (min-width: 900px) and (max-width:1200px){
    .contact-form{
        width: 112%;
        display: flex;
        flex-direction: column;
        height: fit-content;
        padding-bottom: 1rem;
    }
    .c-left{
        margin-left: 2.5rem;
    }
    .c-span1{
        font-size: 4rem;
    }
    .c-right{
        margin-left: 4rem;
        margin-top: -108rem;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 2000px){
    .contact-form{
        height: 50vh;
    }
}

@media only screen and (min-width: 2000px){
    .contact-form {
        height: 50vh;
    }
    .c-span1{
        font-size: 5.5rem;
    }
    .c-right .user{
        width: 50rem;
        height: 7rem;   
        font-size: 1.9rem;   
    }
    
}