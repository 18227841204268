.card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.26);
  background-color:#F0F0F0 ;
  box-shadow: var(--boxShadow);
  border-radius: 20px;
  padding: 10px 26px 2rem 26px;
}

.card span:nth-of-type(1){
  font-size: 1.5rem;
  font-weight: bolder;
}

.card span:nth-of-type(2) {
  color: var(--gray);
  font-size: 18px;
}

.c-button {
  background: #FFFFFF;
  box-shadow: 0px 19px 60px rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  border: none;
  padding: 10px;
  font-size: 16px;
}

.c-button:hover{
    transform: scale(1.1);
    cursor: pointer;
}


@media only screen and (max-width: 540px){
  .card{
    margin-top: 2rem;
    height: max-content;
  }
  .first-card {
    width: 80%;
    margin-left: 2rem;
  }
  .second-card{
    width: 80%;
    margin-left: 0.5rem;
  }
}

@media only screen and (min-width: 540px) and (max-width: 900px){
  .card{
    margin-top: 2rem;
    height: max-content;
  }
  .first-card {
    width: 82%;
    margin-left: 2rem;
  }
  .second-card{
    width: 82%;
    margin-left: 0rem;
  }
  .card span:nth-of-type(1){
    font-size: 1.9rem;
  }
  .card span:nth-of-type(2){
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 900px) and (max-width:1200px){
  .card{
    margin-top: 2rem;
    height: fit-content;
  }
  .first-card {
    width: 82%;
    margin-left: 3rem;
  }
  .second-card{
    width: 79%;
    margin-left: 1.5rem;
  }
  .card span:nth-of-type(1){
    font-size: 1.9rem;
  }
  .card span:nth-of-type(2){
    font-size: 1.5rem;
  }
}

@media only screen and (min-width:1200px) and (max-width: 1600px){
  .card{
    margin-top: 2rem;
    height: 32rem;
    padding-bottom: 3rem;
  }
  .first-card {
    width: 90%;
    margin-left: 1rem;
  }
  .second-card{
    width: 100%;
    margin-left: -7rem;
  }
  .card span:nth-of-type(1){
    font-size: 1.9rem;
  }
  .card span:nth-of-type(2){
    font-size: 1.35rem;
  }
}

@media only screen and (min-width: 2000px) and (max-width: 3200px) {
  /* .card{
    margin-top: 2rem;
    height: 38rem;
  }
  .first-card {
    width: 70%;
    margin-left: -3rem;
  }
  .second-card{
    width: 93%;
    
    margin-left: -13rem;
  } */
  .card span:nth-of-type(1){
    font-size: 2.8rem;
  }
  .card span:nth-of-type(2){
    font-size: 2rem;
  }
  .c-button{
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 3200px){
  .card{
    margin-top: 2rem;
    height: 38rem;
  }
  .first-card {
    width: 68%;
    margin-left: -5rem;
  }
  .second-card{
    width: 96%;
    margin-left: -15rem;
  }
  .card span:nth-of-type(1){
    font-size: 3rem;
  }
  .card span:nth-of-type(2){
    font-size: 2.3rem;
  }
  .c-button{
    font-size: 1.5rem;
  }
  
}
