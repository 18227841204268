.n-wrapper{
    display:flex ;
    justify-content: space-between;
    padding: 0.5rem 3.5rem;
}

.ngo_logo{
    /* height: 10vh; */
    border-radius: 10%;
    margin-left: 1rem;
}

.n-left{
    display: flex;
    flex-direction: column;
    margin-left: 0rem;
}

.n-left-first-container{
    display: flex;
    align-items: center;
}

.sub-title{
    color: rgb(22, 16, 112);
    font-weight: bold;
    margin-top: 0px;
    font-size: 1.2rem;
    margin-left: -10rem;
}

.n-name{
    font-size: 2rem;
    font-weight: bolder
}

.n-name:hover{
    color: var(--green1);
    cursor: pointer;
}

  
.n-right{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 20px;
    margin-right: -15rem;
    margin-top: -1rem;
}
/* .n-list{
    padding-left: 8rem; 
} */
.n-list>ul{
    display: flex;
    gap:2rem;
    /* margin-right: 4rem; */
    width: max-content;
}


.n-li:hover{
    color: var(--green1);
    cursor: pointer;
}
.n-button{
    margin-left: -2rem;
    margin-right: 2rem;
}

.n-right-mobile-view{
    display: none;
}

@media only screen and (max-width: 540px){
    .n-list{
        display: none;
    }
    .n-button{
        display: none;
    }
    .n-name{
        font-size: x-large;
        margin-left: -4rem;
    }
    .n-left{
        margin-left: 0rem;
    }
    .ngo_logo{
        height: 8vh;
    }
    .sub-title{
        font-size: large;
        margin-left: -2rem;
    }
    /* .n-right-mobile-view{
        display: block;
        bottom: 1rem;
        right:0rem;
        background: #f0f0f0;
        position: fixed;
        z-index: 999;
        border-radius: 120px,
    } */
}

@media only screen and (min-width: 540px) and (max-width: 600px){
    .n-left{
        margin-left: 6rem;
    }
}

@media only screen and (min-width: 600px) and (max-width:900px){
    .n-wrapper{
        text-align: center;
    }
    .n-list{
        display: none;
    }
    .n-button{
        display: none;
    }
    .n-name{
        font-size: xx-large;
        margin-left: -2rem;
    }
    .n-left{
        margin-left: 9rem;
    }
    .ngo_logo{
        height: 8vh;
    }
    .sub-title{
        font-size: large;
        margin-left: -2rem;
    }
    /* .n-right-mobile-view{
        display: block;
        bottom: 1rem;
        right:0rem;
        background: #f0f0f0;
        position: fixed;
        z-index: 999;
        border-radius: 120px,
    } */
}

@media only screen and (min-width: 900px) and (max-width:1000px){
    .n-wrapper{
        margin-left: 6rem;
        text-align: center;
    }
    .n-left{
        margin-left: 0rem;
    }
    .n-list{
        display: none;
    }
    .n-button{
        display: none;
    }
    .n-name{
        font-size: 3rem;
    }

    .ngo_logo{
        height: 9vh;
    }
    .sub-title{
        font-size: 1.7rem;
    }
    /* .n-right-mobile-view{
        display: block;
        bottom: 1rem;
        right:0rem;
        background: #f0f0f0;
        position: fixed;
        z-index: 999;
        border-radius: 120px,
    } */
}

@media only screen and (min-width: 1000px) and (max-width:1200px){
    .n-wrapper{
        margin-left: 13rem;
        text-align: center;
    }
    .n-list{
        display: none;
    }
    .n-button{
        display: none;
    }
    .n-name{
        font-size: 3rem;
    }

    .ngo_logo{
        height: 9vh;
    }
    .sub-title{
        font-size: 1.7rem;
    }
    /* .n-right-mobile-view{
        display: block;
        bottom: 1rem;
        right:0rem;
        background: #f0f0f0;
        position: fixed;
        z-index: 999;
        border-radius: 120px,
    } */
}

@media only screen and (min-width: 1200px) and (max-width: 2000px){
    .ngo_logo {
        height: 10vh;
    }
    .n-list {
        display: block;
        padding-left: 8rem;
    }
    .n-list>ul{
        margin-right: 4rem;
    }
}

@media only screen and (min-width: 2000px) and (max-width: 2750px ){
    .n-name{
        font-size: 2.5rem;
    }
    .sub-title{
        font-size: x-large;
    }
    .ngo_logo {
        height: 9vh;
    }
    .n-list{
        font-size: xx-large;
    }
    .n-list>ul{
        margin-left: 54rem;
    }
}

@media only screen and (min-width: 2750px) and (max-width: 3100px) {
    .n-name{
        font-size: 3.5rem;
    }
    .sub-title{
        font-size: xx-large;
    }
    .ngo_logo {
        height: 9vh;
    }
    .n-list{
        font-size: 2.5rem;
    }
    .n-list>ul{
        margin-left: 54rem;
    }
}

@media only screen and (min-width: 3100px) {
    .n-name{
        font-size: 3.5rem;
    }
    .sub-title{
        font-size: xx-large;
    }
    .ngo_logo {
        height: 9vh;
    }
    .n-list{
        font-size: 2.9rem;
    }
    .n-list>ul{
        margin-left: 64rem;
    }
}