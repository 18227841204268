
:root
{
  --yellow: #F5C32C;
    --green1 : #94dcec;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --pur:rgb(235, 222, 243);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --greenCard: rgba(79, 252, 26, 0.42);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.App{
    overflow: hidden;
    color: var(--black);  
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f0f0f0; 
  border-top: 10px solid #94dcec; 
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.button{
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
  background: linear-gradient(180deg, #94dcec 26.71%, #6db3c2 99.36%);
}

.button:hover{
  background: white;
  cursor: pointer;
  color: var(--orange);
  border: 4px solid var(--green1);
}

@media screen and (max-width: 480px) {
  .App {
    padding: 0.5rem 1rem;

  }
}


  

  