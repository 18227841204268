.footer {
    display: flex;
    flex-direction: row;
    gap: 40rem;
    position: relative;
    margin-top: 0rem;
    background-color: #f0f0f0 ;
    height: fit-content;
    color: black;
  }
  

  .first{
    font-family: 'Times New Roman', Times, serif;
    margin-left: 2rem;
  }

  
  @media only screen and (max-width: 540px){
    .footer{
      flex-direction: column;
      margin-left: -1rem;
      width: 109%;
      align-items: center;
      gap: 0rem;
      bottom: -8px;
      margin-top: -2rem;
    }
    .first{
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  @media only screen and (max-width: 550px){
    .footer{
      margin-left: -1rem;
      width: 109%;
      align-items: center;
      gap: 0rem;
      bottom: -8px;
      margin-top: -2rem;
    }
    .first{
      margin-left: 3rem;
      margin-right: 1rem;
    }
  }

  @media only screen and (min-width: 550px) and (max-width:900px){
    .footer{
      margin-left: -1rem;
      width: 109%;
      align-items: center;
      gap: 5rem;
      bottom: -8px;
      margin-top: -2rem;
    }
    .first{
      margin-left: 3rem;
      margin-right: 1rem;
    }
  }

  @media only screen and (min-width: 900px) and (max-width:1200px){
    .footer{
      margin-left: -1rem;
      width: 109%;
      align-items: center;
      gap: 10rem;
      bottom: -8px;
      margin-top: -2rem;
    }
    .first{
      margin-left: 5rem;
      margin-right: 1rem;
    }
  }